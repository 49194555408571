* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
#root {
  width: 100%;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

#root {
  overflow: auto;
}

body {
  position: fixed;
  overflow: hidden;
  display: flex;
  align-items: center;
  overscroll-behavior-y: none;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  color: white;
  background: black;
}

canvas {
  /* position: fixed;
  top: 0;
  left: 0; */
  margin-bottom: -100vh;
}
